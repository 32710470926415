<template>
  <div class="container-layout-custom justify-center full-height ak-text light-theme-text-default" style="max-width: 1980px;">
    <div class="q-pa-xl full-width">
      <div class="row q-mb-xl">
        <div class="text-h4 col-md-9">Review Messages</div>
      </div>

      <div class="row">
        <div class="col-6 row justify-center">
          <q-btn class="col-8" color="primary" @click="showUserSearchModal = true">Find User</q-btn>
        </div>
      </div>

      <div v-if="this.selectedUser && this.threads.length > 0" class="row q-my-xl">
        <div class="text-h5 col-12 q-mb-lg">All Messages for {{ this.selectedUser.label }}</div>
        <q-list class="col-6" bordered separator>
          <q-item v-for="thread in threads" :key="thread.uid" clickable @click="selectThread(thread.uid)" class="full-width">
            <q-item-section>
              <q-item-label>
                <span class="text-caption">Chat with: </span>
                <span class="text-bold">{{ getOtherPerson(thread, 'name') }}</span>
              </q-item-label>
              <q-item-label>
                <span class="text-caption">Chat subject: </span>
                <span class="text-bold">{{ thread.subject }}</span>
              </q-item-label>
            </q-item-section>
            <q-item-section side top>
              <q-item-label caption>Number of Messages: <span class="text-bold">{{ thread.messages.length }}</span></q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <div class="row full-width justify-center q-mt-lg">
          <q-pagination v-if="pageMax > 1" class="col-6" v-model="page" :max="pageMax" :max-pages="10" :direction-links="true" />
        </div>
      </div>

      <div v-if="this.selectedUser.label && noMessages" class="row q-my-xl">
        <div class="text-h5 col-12">No Messages for {{ this.selectedUser.label }}</div>
      </div>

      <Chat :opened="openThread" :thread="selectedThread" @close="openThread = false" :replyable="false" :actingAs="this.selectedUser.value" />

      <q-dialog v-model="showUserSearchModal">
        <UserSearch @userSelected="userSelected" @closeForm="showUserSearchModal = false" scoped/>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import UserSearch from 'components/UserSearch'
import Chat from 'components/messages/Chat'
export default {
  components: { UserSearch, Chat },
  data () {
    return {
      showUserSearchModal: false,
      selectedUser: {},
      allThreads: [],
      openThread: false,
      selectedThread: {},
      noMessages: false,
      page: 1,
      perPage: 20
    }
  },
  computed: {
    pageMax () {
      if (this.allThreads.length === 0) return 0
      return Math.ceil(this.allThreads.length / this.perPage)
    },
    threads () {
      let startIndex = (this.page - 1) * this.perPage
      return this.allThreads.slice(startIndex, startIndex + this.perPage)
    }
  },
  methods: {
    userSelected (user) {
      this.selectedUser = user
      this.showUserSearchModal = false
      this.fetchUserMessages()
    },
    async fetchUserMessages () {
      this.$loading(true, `Fetching Messages for ${this.selectedUser.label}`)
      this.noMessages = false
      this.allThreads = []
      this.openThread = false

      try {
        let res = await this.$axios.get(`${this.$consts.GET_MESSAGES_URL}?user=${this.selectedUser.value}`)
        res.data.forEach(thread => {
          thread.lastMessageDate = thread.messages.slice(-1)[0].created_at
        })
        if (res.data.length < 1) {
          this.noMessages = true
        }
        this.allThreads = res.data
        this.$loading(false)
      } catch (e) {
        this.$loading(false)
      }
    },
    getOtherPerson (thread, prop) {
      return thread.author.uid === this.selectedUser.value ? thread.recipient[prop] : thread.author[prop]
    },
    selectThread (threadUid) {
      this.openThread = false
      let selectedThread = this.threads.find(thread => thread.uid === threadUid)
      this.selectedThread = selectedThread
      this.openThread = true
    }
  }
}
</script>
